import React from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close"; // For toggle visual

interface FloatingSearchButtonProps {
  onClick: () => void;
  showFilters: boolean; // To decide which icon to display
}

const FloatingSearchButton: React.FC<FloatingSearchButtonProps> = ({
  onClick,
  showFilters,
}) => {
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: "fixed",
        left: { xs: '2%', md: '7%', custom: '10%', lg: '30%' },
        bottom: "5%",
        zIndex: 1050,
        backgroundColor: "rgba(245, 245, 245, 0.9)", // Slight transparency
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)", // Enhanced shadow
        borderRadius: "50%",
        width: "60px",
        height: "60px",
        padding: "12px",
        transition: "background-color 0.3s", // Smooth transition for hover
        '&:hover': {
          backgroundColor: "rgba(245, 245, 245)", // Slightly darker on hover
        },
      }}
    >
      {showFilters ? (
        <CloseIcon style={{ fontSize: "32px" }} />
      ) : (
        <SearchIcon style={{ fontSize: "32px" }} />
      )}
    </IconButton>
  );
};

export default FloatingSearchButton;
