import React, { useState, useEffect } from 'react';
import { ThemeProvider, CssBaseline } from "@mui/material";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import theme from "./theme";
import MainPage from "./components/MainPage/MainPage";
import Login from "./components/Login/Login";
import ReviewSubmissions from "./components/SongSubmissionForm/ReviewSubmissions";
import ArtistCardList from "./components/Lists/ArtistCardList/ArtistCardList";
import SongSubmissionForm from "./components/SongSubmissionForm/SongSubmissionForm";
import Navbar from "./components/Navbar/Navbar";
import LandingPage from "./components/LandingPage";
import { useAuth } from "./auth/auth";
import { getCurrentYear } from "./utils/dateUtils";
import { UrlProvider } from './UrlContext';
import CookieBanner from './components/CookieBanner/CookieBanner'; // Import the CookieBanner component

declare global {
  interface Window {
    dataLayer: IArguments[];
    gtag: (...args: any[]) => void;
  }
}

const App: React.FC = () => {
  
  const { isAuthenticated, handleLogout } = useAuth();
  const [showFilters, setShowFilters] = useState(false);
  const [showCookieBanner, setShowCookieBanner] = useState(false);

  const handleAcceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setShowCookieBanner(false);
    // Programmatically initialize Google Analytics here if consent is given
    initGoogleAnalytics();
  };

  const handleDeclineCookies = () => {
    localStorage.setItem('cookiesAccepted', 'false');
    setShowCookieBanner(false);
    // Ensure Google Analytics or other non-essential scripts are not initialized
  };

  const initGoogleAnalytics = () => {
    // Load the Google Analytics script
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-DM9JZ1MGXJ`; // Use your GA Measurement ID
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function(){ window.dataLayer.push(arguments); };
      window.gtag('js', new Date());
      window.gtag('config', 'G-DM9JZ1MGXJ'); // Use your GA Measurement ID
    };
  };


  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (cookiesAccepted === 'true') {
      initGoogleAnalytics();
    }
    // No need to handle the 'false' case here as GA won't be initialized if consent isn't given
    else {
      setShowCookieBanner(true);
    }
  }, []);



  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const ReCAPTCHAStyle = () => {
    return <style>{'.grecaptcha-badge { visibility: hidden; }'}</style>;
  };

  const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const location = useLocation();
    if (!isAuthenticated) {
      return <Navigate to="/login" state={{ from: location }} />;
    }
    return <>{children}</>;
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <UrlProvider>
        <Router>
          <ReCAPTCHAStyle />
          <Navbar
            onSearchClick={toggleFilters}
            handleYearChange={() => {}}
            handleArtistsChange={() => {}}
            showFilters={showFilters}
            toggleFilters={toggleFilters}
            currentYear={getCurrentYear()}
            onLogout={handleLogout}
            isAuthenticated={isAuthenticated}
          />
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/om" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/innsendinger" element={
              <ProtectedRoute>
                <ReviewSubmissions />
              </ProtectedRoute>
            } />
            <Route path="/send-inn" element={<SongSubmissionForm />} />
            <Route path="/artists" element={<ArtistCardList />} />
            {/* Other routes */}
          </Routes>
          {showCookieBanner && (
            <CookieBanner
              onAccept={handleAcceptCookies}
              onDecline={handleDeclineCookies}
            />
          )}
        </Router>
      </UrlProvider>
    </ThemeProvider>
  );
};

export default App;
