import React, { FC } from "react";
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
  FormControl,
} from "@mui/material";

interface StreamFilterButtonProps {
  onChange: (active: boolean) => void;
  active: boolean;
}

const StreamFilterButton: FC<StreamFilterButtonProps> = ({
  onChange,
  active,
}) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value === "enabled");
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="stream-filter-label">Sortering</InputLabel>
      <Select
        labelId="stream-filter-label"
        label="Sortering"
        value={active ? "enabled" : "disabled"}
        onChange={handleChange}
        sx={{
          backgroundColor: "white",
          width: "100%",
          textAlign: "center",
        }}
      >
        <MenuItem value="disabled" sx={{ justifyContent: "center" }}>
          Totalt
        </MenuItem>
        <MenuItem value="enabled" sx={{ justifyContent: "center" }}>
          Trend
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default StreamFilterButton;
