import React, { useState, useEffect } from 'react';
import { Typography, Button, Container, Grid, Paper, Divider, Link } from '@mui/material';
import { red } from '@mui/material/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';


const LandingPage: React.FC = () => {
    const [statistics, setStatistics] = useState({
        songCount: 0,
        artistCount: 0,
        playCountTotal: 0,
        playCountLast7Days: 0
    });

    const baseURL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        fetch(`${baseURL}/api/streamstats`)
            .then((response) => response.json())
            .then((data) => {
                setStatistics({
                    songCount: data.song_data.total,
                    artistCount: data.artist_data.total,
                    playCountTotal: data.song_data.play_count_total,
                    playCountLast7Days: data.song_data.play_count_last_7_days
                });
            })
            .catch((error) => console.error('Error fetching data:', error));
    }, []);
    

    return (
        <Container maxWidth="sm" sx={{ py: 2 }}>
            <Grid container spacing={3} justifyContent="center" alignItems="stretch" direction={"column"}>
                {/* First Paper */}
                <Grid item xs={12} sm={12} md={6} lg={12}>
                    <Paper elevation={3} sx={{ p: 4, bgcolor: 'white', borderRadius: 2, height: '100%' }}>
                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: 'black' }}> {/* Svart tekst */}
                            Velkommen til Russetoppen!
                        </Typography>
                        <Divider sx={{ my: 1 }} />
                        <Typography variant="subtitle1" sx={{ color: 'black' }}> {/* Svart tekst */}
                        Oppdag og utforsk de mest streamede og trendy russelåtene. Vi gir deg daglige oppdateringer og innsikt i russens musikkverden.
                        </Typography>
                        <Button 
                            variant="contained" 
                            sx={{ 
                                mt: 2, 
                                backgroundColor: red[500], 
                                color: 'white', // Hvit tekst på knappen
                                '&:hover': {
                                    backgroundColor: red[700], // Mørkere rød ved hover
                                }
                            }}
                        >
                            Utforsk Topplisten
                        </Button>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={12}>
                    <Paper elevation={3} sx={{ p: 4, bgcolor: 'white', borderRadius: 2, height: '100%' }}>
                        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: 'black' }}>
                            Statistikk
                        </Typography>
                        <Divider sx={{ my: 1 }} />
                        <Typography variant="h6" sx={{ color: 'black' }}>
                            <span style={{ fontSize: '1.2em' }}>🎵</span> {statistics.songCount.toLocaleString()} 
                            <Typography component="span" variant="body2"> sanger</Typography>
                        </Typography>
                        <Typography variant="h6" sx={{ color: 'black' }}>
                            <span style={{ fontSize: '1.2em' }}>🧑‍🎤</span> {statistics.artistCount.toLocaleString()} 
                            <Typography component="span" variant="body2"> artister</Typography>
                        </Typography>
                        <Typography variant="h6" sx={{ color: 'black' }}>
                            <span style={{ fontSize: '1.2em' }}>🌍</span> {statistics.playCountTotal.toLocaleString()} 
                            <Typography component="span" variant="body2"> streams</Typography>
                        </Typography>
                        <Typography variant="h6" sx={{ color: 'black' }}>
                            <span style={{ fontSize: '1.2em' }}>📆</span> {statistics.playCountLast7Days.toLocaleString()} 
                            <Typography component="span" variant="body2"> streams (siste uken)</Typography>
                        </Typography>
                    </Paper>
                </Grid>


                <Grid item xs={12} sm={12} md={6} lg={12}>
                    <Paper elevation={3} sx={{ p: 4, bgcolor: 'white', borderRadius: 2, height: '100%' }}>                      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: 'black' }}>
                            Følg oss på Sosiale Medier
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Link href="https://www.tiktok.com/@russetoppen" target="_blank" rel="noopener noreferrer" sx={{ mr: 10 }}>
                            <FontAwesomeIcon icon={faTiktok} size="2x" style={{ color: red[500] }} />
                        </Link>
                        <Link href="https://www.instagram.com/russetoppen" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} size="2x" style={{ color: red[500] }} />
                        </Link>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default LandingPage;
