import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { RankDisplay } from "./SongCardHelpers"; // Import RankDisplay type

export const cardMediaContainerStyles: SxProps<Theme> = {
  position: "relative",
  width: "100%",
  paddingBottom: "100%",
};

export const cardMediaStyles: SxProps<Theme> = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "5px 0 0 5px",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
};

export const contentStyles: SxProps<Theme> = (theme) => ({
  padding: "1rem",
  backgroundColor: "background.paper",
  [theme.breakpoints.down("sm")]: {
    padding: "0.5rem",
    "& h6, & p": {
      fontSize: "0.85rem",
    },
    "& h6": {
      marginBottom: "0.25rem",
    },
  },
});

export const rankStyles = (rankDisplay: RankDisplay): SxProps<Theme> => ({
  fontSize: rankDisplay.fontSize,
  zIndex: 2,
  textShadow: rankDisplay.textShadow,
  color: rankDisplay.color,
  marginTop: "-8px",
});
