import * as React from "react";
import ArtistCard from "../../Cards/ArtistCard/ArtistCard";
import { Box, Grid, Container } from "@mui/material";

interface Artist {
  spotify_id: string;
  name: string;
  image_url: string | null;
  song_count: number;
  stream_count_total: number;
  average_stream_count: number;
  last_7_days_gain: number;
}

interface ApiResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Artist[];
}

const ArtistCardList: React.FC = () => {
  const [artists, setArtists] = React.useState<Artist[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true);

  // Use the environment variable for the base URL
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const [nextPageURL, setNextPageURL] = React.useState<string | null>(
    `${baseURL}/api/artists_toplist/?format=json`
  );

  const fetchArtists = async () => {
    if (!nextPageURL || loading) {
      setHasMore(false);
      return;
    }

    setLoading(true);
    const response = await fetch(nextPageURL);
    const data: ApiResponse = await response.json();
    setArtists((prevArtists) => [...prevArtists, ...data.results]);
    setNextPageURL(data.next);
    setLoading(false);

    if (data.next === null) {
      setHasMore(false);
    }
  };

  React.useEffect(() => {
    fetchArtists();
  }, []);

  const observer = React.useRef<IntersectionObserver | null>(null);
  const lastArtistElementRef = React.useCallback(
    (node: HTMLDivElement | null) => {
      if (loading || !hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchArtists();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <Container maxWidth="sm">
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={1}>
          {artists.map((artist, index) => (
            <Grid
              item
              xs={12}
              key={artist.spotify_id}
              ref={index === artists.length - 1 ? lastArtistElementRef : null}
            >
              {" "}
              {/* Updated this line */}
              <Box sx={{ width: "100%", margin: "0 auto" }}>
                <ArtistCard artist={artist} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default ArtistCardList;
