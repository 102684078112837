//navbarStyles.ts
import { styled } from '@mui/system';
import { AppBar, IconButton, Toolbar } from '@mui/material';


export const StyledDiv = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#e3e1e1', // Transparent background
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,
  width: '100%',
  margin: 0,
  padding: '0px',
}));

export const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
});

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: '#000',
  paddingLeft: 0,  
  fontSize: '1.2rem',  // <-- Adjust this value for the icon size
  [theme.breakpoints.down('sm')]: {
      fontSize: '1.3rem', // adjust this value for smaller screens if needed
  },
}));



export const MenuButtonStyle = styled(IconButton)({
  color: '#000', // Set color to black
});

export const HamburgerPlaceholder = styled('div')({
  width: '30px',  // This width should be the same as the hamburger icon's width including padding.
});

export const FunFactText = styled('div')(({ theme }) => ({
  color: '#000', 
  textAlign: 'center',
  flexGrow: 1,
  marginLeft: 'auto',
  marginRight: '0px', 
  fontSize: '11px',
  padding: '5px 10px', 
  borderRadius: '10px', 
  background: '#f0f0f0', 
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)', 
  border: '1px solid #aaa', 
  position: 'relative', 
  maxWidth: '80px', // take full width on small screens

  [theme.breakpoints.up('sm')]: {
    flexGrow: 0,   // prevent stretching on larger screens
    width: '60%', // take 60% of the width on medium screens
  },
  
  [theme.breakpoints.up('md')]: {
    flexGrow: 0,   // prevent stretching on larger screens
    width: '40%', // take 40% of the width on large screens
  },

  [theme.breakpoints.up('lg')]: {
    flexGrow: 0,   // prevent stretching on larger screens
    width: '30%', // take 30% of the width on extra large screens
  }
}));





export const EqualSpaceContainer = styled('div')({
  width: '120px',  // since you mentioned the largest offset was 119px
  display: 'flex',
  alignItems: 'center',  // vertical centering
});

