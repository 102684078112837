import React from 'react';
import { Box, Typography, Button, useTheme, useMediaQuery } from '@mui/material';

interface CookieBannerProps {
  onAccept: () => void;
  onDecline: () => void;
}

const CookieBanner: React.FC<CookieBannerProps> = ({ onAccept, onDecline }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const lighterRed = "#EF5350"; // A lighter shade of red

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: 'background.default',
        color: 'text.primary',
        boxShadow: 3,
        p: isMobile ? 2 : 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
        borderRadius: '15px' // Apply rounded corners to the entire box
      }}
    >
    <Typography variant={isMobile ? 'body2' : 'body1'}>
      Vi bruker cookies for å forbedre brukeropplevelsen og analysere nettstedstrafikken. Ved å akseptere godtar du bruken av cookies. Du kan avslå ikke-nødvendige cookies.
    </Typography>


      <Box mt={2} display="flex">
        <Button variant="contained" sx={{ mx: 1, bgcolor: lighterRed }} onClick={onAccept}>
          Godta
        </Button>
        <Button variant="outlined" sx={{ mx: 1, color: lighterRed, borderColor: lighterRed }} onClick={onDecline}>
          Avslå
        </Button>
      </Box>
    </Box>
  );
};

export default CookieBanner;
