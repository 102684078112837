import React from "react";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";

interface FloatingResetButtonProps {
  onClick: (event: React.MouseEvent) => void;
}

const FloatingResetButton: React.FC<FloatingResetButtonProps> = ({ onClick }) => {
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: "fixed",
        left: { xs: '2%', md: '7%', custom: '10%', lg: '30%' },
        bottom: "15%", // Adjusted to position above the FloatingSearchButton
        zIndex: 1050,
        backgroundColor: "rgba(245, 245, 245, 0.9)", // Slight transparency
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)", // Enhanced shadow
        borderRadius: "50%",
        width: "60px",
        height: "60px",
        padding: "30px",
        transition: "background-color 0.3s", // Smooth transition for hover
        '&:hover': {
          backgroundColor: "rgba(245, 245, 245)", // Slightly darker on hover
        },
      }}
    >
      <RefreshIcon style={{ fontSize: "32px" }} />
    </IconButton>
  );
};

export default FloatingResetButton;