export const getCurrentYear = (): number => {
  const now = new Date();
  const currentYear = now.getFullYear();
  return now.getMonth() > 4 || (now.getMonth() === 4 && now.getDate() > 17)
    ? currentYear + 1
    : currentYear;
};

// const getCurrentWeek = (): number => {
//   const today = new Date();
//   const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
//   const pastDaysOfYear =
//     (today.valueOf() - firstDayOfYear.valueOf()) / 86400000;
//   const currentWeek = Math.ceil(
//     (pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7
//   );
//   return currentWeek - 1; // Return previous week
// };
export const getCurrentWeek = (): number | null => {
  // Original logic here to calculate the week number
  // ...
  return null; // Default to null when no week is selected
};

