import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import debounce from "@mui/material/utils/debounce";

interface Artist {
  id: number;
  name: string;
}

interface ArtistSearchBarProps {
  onArtistSearchChange: (artists: Artist[]) => void;
  artistSearchTerm: string[];
  reset?: boolean;
}

const ArtistSearchBar: React.FC<ArtistSearchBarProps> = ({
  onArtistSearchChange,
  artistSearchTerm,
  reset,
}) => {
  const [inputValue, setInputValue] = React.useState("");
  const [selectedArtists, setSelectedArtists] = React.useState<Artist[]>(
    artistSearchTerm.map((name) => ({ id: 0, name }))
  ); // Updated this line
  const [options, setOptions] = React.useState<readonly Artist[]>([]);

  const fetchArtists = React.useMemo(
    () =>
      debounce((query: string) => {
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        fetch(`${baseURL}/api/artist-suggestions/?format=json&query=${query}`)
          .then((response) => response.json())
          .then((data) => setOptions(data))
          .catch((error) =>
            console.error("Error fetching artist data:", error)
          );
      }, 400),
    []
  );


  React.useEffect(() => {
    if (reset) {
      setInputValue("");
      setSelectedArtists([]); // Reset the selected artists state
    }
  }, [reset]);

  // Added this useEffect to update the selectedArtists state when artistSearchTerm changes
  React.useEffect(() => {
    setSelectedArtists(artistSearchTerm.map((name) => ({ id: 0, name }))); // Updated this line
  }, [artistSearchTerm]);

  React.useEffect(() => {
    if (inputValue === "") {
      setOptions([]);
    } else {
      fetchArtists(inputValue);
    }
  }, [inputValue, fetchArtists]);

  React.useEffect(() => {
    console.log("Selected Artists in ArtistSearchBar:", selectedArtists);
  }, [selectedArtists]);

  return (
    <Autocomplete
      multiple
      id="artist-search-input"
      options={options}
      getOptionLabel={(option: Artist) => option.name}
      filterSelectedOptions
      value={selectedArtists} // This is where the selected artists are being displayed
      onChange={(event, newValues) => {
        setSelectedArtists(newValues);
        onArtistSearchChange(newValues);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Søk etter artist"
          placeholder="Søk etter flere artister"
        />
      )}
      sx={{
        width: "100%",
        maxWidth: "100%",
        margin: "0 auto",
        background: "white",
      }}
    />
  );
};

export default ArtistSearchBar;
