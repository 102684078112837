import React, { useState } from 'react';
import { TextField, MenuItem, Grid, Button, Typography, Link, Container, Paper, Divider, IconButton  } from '@mui/material';
import { red } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const SongSubmissionForm: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const currentYear = new Date().getFullYear();
  const years = Array.from({length: currentYear - 2008}, (_, i) => currentYear + 2 - i);
  
  const [year, setYear] = useState("");
  const [spotifyUrl, setSpotifyUrl] = useState('');
  const [group, setGroup] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault();
    setErrorMessage(''); 
    setSuccessMessage('');


    const spotifyUrlRegex = /^https:\/\/open\.spotify\.com\/track\/[0-9a-zA-Z]{22}(?:\?si=.*)?$/;

    const spotifyAlbumUrlRegex = /^https:\/\/open\.spotify\.com\/album\/[0-9a-zA-Z]{22}(?:\?si=.*)?$/;

    if(spotifyAlbumUrlRegex.test(spotifyUrl)) {
      setErrorMessage('Dette er en link til et album, vi trenger linken til sangen. Eksempel: https://open.spotify.com/track/6RqfD0pbD769SEd3NVBZlo?si=233ab18e80334851');
      return;
    }

    if (!spotifyUrlRegex.test(spotifyUrl)) {
      setErrorMessage('Vennligst skriv inn en gyldig Spotify-link.');
      return;
    }

    if (isNaN(Number(year))) {
      setErrorMessage('Vennligst velg et år.');
      return;
    }

    if (group.length > 50) {
      setErrorMessage('Gruppenavn kan maks inneholde 50 tegn.');
      return;
    }

    // Validerer at 'group' kun inneholder alfanumeriske tegn og spesialtegn
    const groupRegex = /^[a-zA-Z0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?æøå]*$/;
    if (!groupRegex.test(group)) {
      setErrorMessage('Gruppenavn inneholder ugyldige tegn.');
      return;
    }

    window.grecaptcha.ready(async () => {
      const recaptchaToken = await window.grecaptcha.execute('6Lf_keAmAAAAABFY-6A7ZoffbWiaTQjCrH7hmgXw', { action: 'submit' });

      try {
        const response = await fetch(`${baseURL}/api/submit-song/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            spotify_url: spotifyUrl,
            year: Number(year),
            group: group,
            recaptchaToken
          })
        });

        if (response.ok) {
          setSuccessMessage('Sangen ble sendt inn!');
          setYear('');
          setSpotifyUrl('');
          setGroup('');
        } else {
          const data = await response.json();
          setErrorMessage(data.error);
        }
      } catch (error) {
          console.error('Error:', error);
          setErrorMessage('En feil oppstod. Vennligst prøv igjen senere.');
      }
    });
  }

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };
  
return (
    <Container maxWidth="sm" sx={{ py: 2 }}>
        <Grid container spacing={3} direction="column" justifyContent="center">
            {/* 'Send inn din russelåt' boks */}
            <Grid item xs={12} md={8} lg={6}>
                <Paper elevation={3} sx={{ p: 4, bgcolor: 'white', borderRadius: 2 }}>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: 'black' }}>
                      Send inn russelåt!
                    </Typography>
                    <Divider sx={{ my: 2 }} />

                    {errorMessage && (
                        <Typography color="error" gutterBottom>{errorMessage}</Typography>
                    )}
                    {successMessage && (
                        <Typography color="success" gutterBottom>{successMessage}</Typography>
                    )}

                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Spotify Link"
                            variant="outlined"
                            value={spotifyUrl}
                            onChange={(e) => setSpotifyUrl(e.target.value)}
                            required
                            fullWidth
                            margin="normal"
                            sx={{ 
                                '& .MuiOutlinedInput-root': { 
                                    backgroundColor: 'white',
                                    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)'
                                } 
                            }}
                        />

                        <Grid container spacing={1} sx={{ mb: 0 }}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    label="År"
                                    value={year}
                                    onChange={(e) => setYear(e.target.value)}
                                    required
                                    fullWidth
                                    sx={{ 
                                        '& .MuiOutlinedInput-root': { 
                                            backgroundColor: 'white',
                                            boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)'
                                        } 
                                    }}
                                >
                                    {years.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Gruppe"
                                    variant="outlined"
                                    value={group}
                                    onChange={(e) => setGroup(e.target.value)}
                                    required
                                    fullWidth
                                    sx={{ 
                                        '& .MuiOutlinedInput-root': { 
                                            backgroundColor: 'white',
                                            boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)'
                                        } 
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Button 
                            type="submit" 
                            variant="contained" 
                            fullWidth 
                            sx={{ 
                                mt: 2, 
                                backgroundColor: red[500], 
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: red[700],
                                }
                            }}
                        >
                            Send inn
                        </Button>
                    </form>
                    
                    <Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }}>
                        Dette nettstedet er beskyttet av reCAPTCHA, og Googles
                        <Link href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer"> personvernregler</Link> og
                        <Link href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer"> vilkår for bruk</Link> gjelder.
                    </Typography>
                </Paper>
            </Grid>

 {/* 'Kvalifikasjonskrav for Russelåter' Box */}
      <Grid item xs={12} md={8} lg={6}>
        <Paper elevation={3} sx={{ p: 4, bgcolor: 'white', borderRadius: 2, mt: 2 }}>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: 'black' }}>
            Krav
            <IconButton onClick={handleExpandClick} sx={{ float: 'right' }}>
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Typography>
          <Divider sx={{ my: 2 }} />
          {isExpanded && (
            <>
              <Typography variant="body1" sx={{ color: 'black', marginBottom: '1rem' }}>
                En russelåt må ha en klar tilknytning til en spesifikk russegruppe. Dette kan være alt fra egenproduserte låter til sanger laget av profesjonelle artister eller russeartister, hvor tilknytningen til gruppen er tydelig. Låten må enten nevne russegruppens navn i tittelen eller inkludere gruppens logo. Dette kravet er avgjørende for å sikre at plattformen vår forblir dedikert til russelåter og ikke blir oversvømmet av vanlige sanger som ikke er relatert til russegrupper. Vår nøye vurderingsprosess sikrer at kun låter som oppfyller disse kriteriene blir akseptert, for å opprettholde en autentisk og relevant samling av russelåter.                {/* Rest of the text */}
              </Typography>
              <Typography variant="body1" sx={{ color: 'black' }}>
Skulle det være en låt du har foreslått som ikke har blitt publisert etter en stund, oppfordrer vi deg til å ta kontakt direkte med artisten eller produsenten bak verket. Oppfordre dem til å kontakte oss på kontakt@russetoppen.no med en bekreftelse på låtens spesifikke tilhørighet til russegruppen. Dette vil akselerere vår vurderingsprosess og bidra til at ditt bidrag raskere blir tilgjengelig i vår katalog.              </Typography>
            </>
          )}
        </Paper>
      </Grid>
        </Grid>
    </Container>
);
};

export default SongSubmissionForm;