import * as React from 'react';
import { Fab, useScrollTrigger, Zoom } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ScrollTopButton: React.FC = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Zoom in={trigger}>
<Fab 
  color="primary" 
  size="medium" 
  onClick={handleClick} 
  sx={{
    position: 'fixed',
    bottom: '15%',
    zIndex: 1050,
    backgroundColor: '#C62828',
    '&:hover': {
      backgroundColor: '#bf5656',
    },
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    padding: '12px',
    right: { xs: '2%', md: '7%', custom: '10%', lg: '30%' },
  }}
>
  <KeyboardArrowUpIcon style={{ fontSize: '32px' }} />
</Fab>

    </Zoom>
  );
};

export default ScrollTopButton;
