// Navbar.tsx
import React, { useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import { AppBar, IconButton, Container, Button, useMediaQuery, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import NavDrawer from "../NavDrawer/NavDrawer";
import NavBarContents from "./NavBarContents";
import { StyledAppBar, StyledIconButton } from "./navbarStyles";
import { useNavigate } from "react-router-dom";
import { getCurrentYear, getCurrentWeek } from "../../utils/dateUtils";

// Interface definitions
interface Artist {
  id: number;
  name: string;
}

interface NavbarProps {
  onSearchClick: () => void;
  handleYearChange: (year: number | null) => void;
  handleArtistsChange: (artists: Artist[]) => void;
  showFilters: boolean;
  toggleFilters: () => void;
  currentYear: number;
  onLogout: () => void;
  isAuthenticated: boolean;
}

const NavBar: React.FC<NavbarProps> = ({
  onSearchClick,
  handleYearChange,
  handleArtistsChange,
  showFilters,
  toggleFilters,
  currentYear,
  onLogout,
  isAuthenticated,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const resetToDefault = () => {
    // Setter opp default query-parametere
    const defaultSearchParams = new URLSearchParams();
    defaultSearchParams.set("year", getCurrentYear().toString());
    defaultSearchParams.set("search", "");
    defaultSearchParams.set("artists", "");
    defaultSearchParams.set("streamFilter", "false");
    
    // Oppdaterer URL med default query-parametere og navigerer til "/"
    navigate(`/?${defaultSearchParams.toString()}`);
  };

  const handleLogoClick = () => {
    resetToDefault();
    window.scrollTo(0, 0); // This line scrolls the page to the top
  };


  const toggleDrawer = (isOpen: boolean) => () => {
    setOpen(isOpen);
  };

  return (
    <StyledAppBar position="sticky">
      <Toolbar>
        <Container maxWidth="lg" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>          <Button onClick={handleLogoClick}>
            <img src="https://i.imgur.com/tPFnxzE.png" alt="Logo" style={{ height: "28px" }} />
          </Button>
          <div>
            {isMobile ? (
              <StyledIconButton edge="end" aria-label="menu" onClick={toggleDrawer(true)}>
                <MenuIcon style={{ fontSize: "24px" }} />
              </StyledIconButton>
            ) : (
              <NavBarContents onLogout={onLogout} isAuthenticated={isAuthenticated} />
            )}
          </div>
        </Container>
      </Toolbar>
      {isMobile && (
        <NavDrawer open={open} onClose={toggleDrawer(false)} onLogout={onLogout} isAuthenticated={isAuthenticated} />
      )}
    </StyledAppBar>
  );
};

export default NavBar;