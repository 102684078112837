import * as React from "react";
import {
  SelectChangeEvent,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";

interface YearPickerProps {
  onYearChange: (year: number | null) => void; // allows null for 'All Years'
  selectedYear: number | null; // allows null for 'All Years'
  currentYear: number;
}

const YearPicker: React.FC<YearPickerProps> = ({
  onYearChange,
  selectedYear,
  currentYear,
}) => {
  const [year, setYear] = React.useState<number | null>(selectedYear === -1 ? null : selectedYear);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value;
    if (value === "All" || value === "-1") {
      setYear(null);
      onYearChange(null);
    } else {
      const numValue = parseInt(value as string);
      setYear(numValue);
      onYearChange(numValue);
    }
  };

  React.useEffect(() => {
    setYear(selectedYear === -1 ? null : selectedYear);
  }, [selectedYear]);

  // Explicitly type the years array
  const years: number[] = [];
  for (let i = currentYear; i >= 2009; i--) {
    years.push(i);
  }

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="year-label">År</InputLabel>
      <Select
        labelId="year-label"
        label="År"
        value={year === null ? "All" : year.toString()}
        onChange={handleChange}
        sx={{
          backgroundColor: "white", // Set the entire component's background to white
          width: "100%",
          textAlign: "center", // Center the selected content
          "& .MuiSelect-select": {
            paddingRight: "px", // to avoid the dropdown icon overlapping the text
          },
        }}
      >
        <MenuItem value="All" sx={{ justifyContent: "center" }}>
        Alle
        </MenuItem>{" "}
        {/* Apply justifyContent: 'center' */}
        {years.map((year: number) => (
          <MenuItem
            key={year}
            value={year.toString()}
            sx={{ justifyContent: "center" }}
          >
            {" "}
            {/* Apply justifyContent: 'center' */}
            {year}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default YearPicker;
