import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/auth';
import { Box, TextField, Button, Paper } from '@mui/material';

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [totpCode, setTotpCode] = useState('');
  const { handleLogin } = useAuth();
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const submitLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    console.log(`start:REACT_APP_API_BASE_URL: ${baseURL}`)
    e.preventDefault();

    window.grecaptcha.ready(async () => {
      try {
        const recaptchaToken = await window.grecaptcha.execute('6Lf_keAmAAAAABFY-6A7ZoffbWiaTQjCrH7hmgXw', { action: 'submit' });
        const wasSuccessful = await handleLogin(username, password, totpCode, recaptchaToken);
        if (wasSuccessful) {
          navigate('/innsendinger');
        }
      } catch (error) {
        console.error("Error during login:", error);
      }
    });
  };

  return (
    <Paper elevation={3} sx={{ maxWidth: 300, mx: 'auto', p: 3, mt: 5 }}>
      <Box component="form" onSubmit={submitLogin} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <img src="https://i.imgur.com/tPFnxzE.png" alt="Logo" />
        <TextField
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Code"
          value={totpCode}
          onChange={(e) => setTotpCode(e.target.value)}
          required
          fullWidth
          margin="normal"
        />
        <Button 
          type="submit" 
          variant="contained" 
          sx={{ bgcolor: 'red', ':hover': { bgcolor: 'darkred' } }} 
          fullWidth
        >
          Login
        </Button>
      </Box>
    </Paper>
  );
};

export default Login;
