import React, { FC, useState, useEffect } from "react";
import { Box, Container, Grid, Button, Typography } from "@mui/material";
import Navbar from "../Navbar/Navbar";
import CardList from "../Lists/SongCardList/CardList";
import ScrollTopButton from "../ScrollTopButton/ScrollTopButton";
import SearchBar from "../Search/SearchBar";
import ArtistSearchBar from "../Search/ArtistSearchBar";
import YearPicker from "../YearPicker/YearPicker";
import FloatingSearchButton from "../Search/FloatingSearchButton";
import FloatingResetButton from "../Search/FloatingResetButton";
import StreamFilterButton from "../StreamFilterButton/StreamFilterButton";
import WeekPicker from "../WeekPicker/WeekPicker";
import { getCurrentYear, getCurrentWeek } from "../../utils/dateUtils";
import theme from "../../theme";
import { useAuth } from "../../auth/auth";
import { Artist } from "../../types";
import { useSearchParams } from "react-router-dom";
import { useUrl } from '../../UrlContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from "react-router-dom"; // Import useNavigate
import AddButton from '../AddButton/AddButton'; // Adjust the path as necessary

const MainPage: FC = () => {

  const { url, setUrl } = useUrl();
  // Initialize the useSearchParams hook
  const [searchParams, setSearchParams] = useSearchParams();
// Define a helper function to check if a parameter is present in the URL
  const isParamPresent = (paramName: string) => {
    return searchParams.has(paramName);
  };

  // Only initialize 'initialYear' if 'year' parameter is present in the URL
  const initialYear = isParamPresent("year") 
    ? parseInt(searchParams.get("year") || "", 10) 
    : 2025; // Default year set to 2024  // Set the initial states based on URL search parameters or use default values
  const initialWeek = parseInt(searchParams.get("week") || "") || getCurrentWeek();
  const initialSearchTerm = searchParams.get("search") || "";
  const initialArtistsParam = searchParams.get("artists") || "";
  const initialArtists = initialArtistsParam ? initialArtistsParam.split(",") : [];
  const initialStreamFilter = searchParams.get("streamFilter") === "true";

  // Define state using initial values
// Initialize the state to handle both numbers and 'all' string
  // Initialize the state to handle both numbers and -1 for 'all'
  const [selectedYear, setSelectedYear] = useState<number | null>(initialYear);
  const [selectedWeek, setSelectedWeek] = useState<number | null>(initialWeek);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [artistSearchTerm, setArtistSearchTerm] = useState<string[]>(initialArtists);
  const [streamFilter, setStreamFilter] = useState(initialStreamFilter);
  const [showFilters, setShowFilters] = useState(false); // This may not need to be in URL


  const handleYearChange = (year: number | null) => {
    setSelectedYear(year);

    const newSearchParams = new URLSearchParams(searchParams);
    // Set the year parameter to '-1' if year is 'Alle'
    newSearchParams.set("year", year === null ? '-1' : year.toString());
      // Retain other search parameters
      if (searchTerm) newSearchParams.set("search", searchTerm);
      if (artistSearchTerm.length) newSearchParams.set("artists", artistSearchTerm.join(","));
      newSearchParams.set("streamFilter", streamFilter.toString());

      // Update the URL search parameters
      setSearchParams(newSearchParams, { replace: true });
  };

  const handleStreamFilterChange = (isActive: boolean) => {
    setStreamFilter(isActive);

    // Construct new search parameters
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("streamFilter", isActive.toString());

    // Retain other search parameters
    if (selectedYear) newSearchParams.set("year", selectedYear.toString());
    if (searchTerm) newSearchParams.set("search", searchTerm);
    if (artistSearchTerm.length) newSearchParams.set("artists", artistSearchTerm.join(","));

    // Update the URL search parameters
    setSearchParams(newSearchParams, { replace: true });
  };


  const [totalSongsCount, setTotalSongsCount] = useState(0);

  // Callback function to update total songs count
  const handleTotalSongsCountChange = (count: number) => {
    setTotalSongsCount(count);
  };

  const handleSearchChange = (term: string) => {
    setSearchTerm(term);

    // Construct new search parameters
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("search", term);
    // Retain other search parameters
    if (selectedYear) newSearchParams.set("year", selectedYear.toString());
    if (artistSearchTerm.length) newSearchParams.set("artists", artistSearchTerm.join(","));
    newSearchParams.set("streamFilter", streamFilter.toString());

    // Update the URL search parameters
    setSearchParams(newSearchParams, { replace: true });
  };

  const handleArtistsChange = (artists: Artist[]) => {
    const artistNames = artists.map(artist => artist.name);
    setArtistSearchTerm(artistNames);

    // Construct new search parameters
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("artists", artistNames.join(","));
    // Retain other search parameters
    if (selectedYear) newSearchParams.set("year", selectedYear.toString());
    if (searchTerm) newSearchParams.set("search", searchTerm);
    newSearchParams.set("streamFilter", streamFilter.toString());

    // Update the URL search parameters
    setSearchParams(newSearchParams, { replace: true });
  };

  const resetToDefault = (event: React.MouseEvent) => {
    console.log("resetToDefault triggered");
    event.preventDefault();

    // Update the state to default values
    const defaultYear = getCurrentYear();
    setSelectedYear(defaultYear);
    setSearchTerm("");
    setArtistSearchTerm([]);
    setStreamFilter(false);
    setSelectedWeek(getCurrentWeek());

    const newSearchParams = new URLSearchParams();
    newSearchParams.set("year", getCurrentYear().toString());
    newSearchParams.set("streamFilter", "false");
    setSearchParams(newSearchParams, { replace: true });
  };

// Define the toggleFilters function
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const { handleLogout } = useAuth();

  useEffect(() => {
    // Extract parameters from URL
    const yearParam = searchParams.get("year");
    const weekParam = searchParams.get("week");
    const searchTermParam = searchParams.get("search");
    const artistsParam = searchParams.get("artists");
    const streamFilterParam = searchParams.get("streamFilter");

    // Update internal states
    setSelectedYear(yearParam ? parseInt(yearParam, 10) : getCurrentYear());
    setSelectedWeek(weekParam ? parseInt(weekParam, 10) : getCurrentWeek());
    setSearchTerm(searchTermParam || "");
    setArtistSearchTerm(artistsParam ? artistsParam.split(",") : []);
    setStreamFilter(streamFilterParam === "true");
  }, [searchParams]); // Dependency on searchParams ensures this runs when URL parameters change


  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const showResetButton = showFilters || searchTerm || artistSearchTerm.length > 0;
  const showSearchFields = isMobile ? showFilters : true;
  const fixedContainerHeight = isMobile ? (showFilters ? '250px' : '90px') : '100px';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <Box
      sx={{
        position: "relative",
        minHeight: "100vh",
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Container maxWidth="lg">
        {isMobile && <AddButton />}
        {isMobile && <ScrollTopButton />}
        <Container
          sx={{
            position: "fixed",
            zIndex: 10,
            backgroundColor: "#f0f0f0",
            borderRadius: "0 0 10px 10px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            padding: theme.spacing(2, 2),
            overflow: 'hidden',
            transition: 'max-height 0.5s ease-in-out',
            maxHeight: isMobile ? (showFilters ? '500px' : '83px') : 'none', // Endret her
            maxWidth: isMobile ? '92%' : 'none',
          }}
        >
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={6} sm={6} md={6} lg={2}>
              <YearPicker
                  onYearChange={handleYearChange}
                  selectedYear={selectedYear}
                  currentYear={getCurrentYear()}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={2}>
              <StreamFilterButton
                onChange={() => handleStreamFilterChange(!streamFilter)}
                active={streamFilter}
              />
            </Grid>
            {showSearchFields && (
              <>
                <Grid item xs={12} md={6} lg={4}>
                  <SearchBar
                    onSearchChange={handleSearchChange}
                    searchTerm={searchTerm}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <ArtistSearchBar
                    onArtistSearchChange={handleArtistsChange}
                    artistSearchTerm={artistSearchTerm}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Container>

        {isMobile && (
          <>
            {showResetButton && (
              <FloatingResetButton onClick={resetToDefault} />
            )}
            <FloatingSearchButton onClick={toggleFilters} showFilters={showFilters} />
          </>
        )}
    <Box sx={{ paddingTop: fixedContainerHeight }}>
          <CardList
            listId="mainList"
            searchTerm={searchTerm}
            artistSearchTerm={artistSearchTerm.join(",")}
            displayDetails={["songDetails", "streamCount"]}
            selectedYear={selectedYear}
            orderByStreamGain={streamFilter}
            selectedWeek={selectedWeek}
            onTotalSongsCountChange={handleTotalSongsCountChange} // Pass the callback
          />
        </Box>
            {/* Floating Box for Total Songs Count */}
{totalSongsCount > 0 && (
  <Box
    sx={{
      position: "fixed",
      bottom: theme.spacing(2),
      left: "50%",
      transform: "translateX(-50%)",
      backgroundColor: "rgba(255, 255, 255, 0.7)", // Semi-transparent white
      backdropFilter: "blur(10px)", // Glassmorphic effect
      borderRadius: "20px", // More rounded corners
      boxShadow: "0 4px 10px rgba(0,0,0,0.3)",
      padding: theme.spacing(1, 2),
      zIndex: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "all 0.3s ease-in-out", // Smooth transition
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.9)", // Change color on hover
        cursor: "pointer",
      }
    }}
  >
    <Typography variant="subtitle1" color="textSecondary" sx={{ ml: 1 }}>
      Sanger: {totalSongsCount}
    </Typography>
  </Box>
)}

      </Container>
    </Box>
  );
};

export default MainPage;
