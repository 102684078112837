import { Song } from "./SongCardTypes"; // Import RankDisplay type

export const BILLION = 1_000_000_000;
export const MILLION = 1_000_000;
export const TEN_MILLION = 10_000_000;
export const HUNDRED_THOUSAND = 100_000;
export const THOUSAND = 1_000;

export interface RankDisplay {
  symbol: string;
  fontSize: string;
  textShadow: string;
  color: string;
}

export const displayRank = (
  song: Song,
  selectedYear: number | null,
  orderByStreamGain: boolean | undefined
): RankDisplay => {
  // Default orderByStreamGain to false if undefined
  orderByStreamGain = orderByStreamGain ?? false;

  let rankToDisplay;

  // Determine the rank based on selectedYear and orderByStreamGain
  if (orderByStreamGain) {
    // If "Alle" (-1) or no year selected (null), use global trend rank, otherwise yearly trend rank
    rankToDisplay = selectedYear && selectedYear !== -1 ? song.yearlyTrendRank : song.globalTrendRank;
  } else {
    // If "Alle" (-1) or no year selected (null), use global rank, otherwise yearly rank
    rankToDisplay = selectedYear && selectedYear !== -1 ? song.yearlyRank : song.globalRank;
  }

  // Check if rankToDisplay is undefined, null, or any other indicator of no data
  if (rankToDisplay == null || rankToDisplay <= 0) { // assuming rank cannot be zero or negative
    return {
      symbol: "No data",
      fontSize: "1rem",
      textShadow: "none",
      color: "grey",
    };
  }

  // Common styles for rank display
  const commonStyles = {
    fontSize: "1.5rem",
    textShadow: "1px 0px 0px #000, 0px 1px 0px #000, 0px -1px 0px #000, -1px 0px 0px #000",
  };

  // Determine the display based on the rank
  switch (rankToDisplay) {
    case 1:
      return {
        symbol: "🥇",
        color: "gold",
        ...commonStyles,
      };
    case 2:
      return {
        symbol: "🥈",
        color: "silver",
        ...commonStyles,
      };
    case 3:
      return {
        symbol: "🥉",
        color: "#cd7f32",
        ...commonStyles,
      };
    default:
      return {
        symbol: `#${rankToDisplay}`,
        fontSize: "1.2rem",
        textShadow: "none",
        color: "black",
      };
  }
};


export const formatStreamCount = (count: number | null | undefined): string => {
  if (count === null || count === undefined) return "Ingen data"; // Handle null or undefined

  if (count >= BILLION) return `${Math.floor(count / BILLION)}b`;
  if (count >= TEN_MILLION) return `${Math.floor(count / MILLION)}m`;
  if (count >= MILLION) return `${(count / MILLION).toFixed(1)}m`;
  if (count >= HUNDRED_THOUSAND) return `${Math.floor(count / THOUSAND)}k`;
  if (count >= THOUSAND) return `${(count / THOUSAND).toFixed(1)}k`;

  return count.toString();
};


export const formatArtists = (artist: string): string => {
  const artists = artist.split(",");
  if (artists.length <= 2) return artist;

  return `${artists[0]}, ${artists[1]} +${artists.length - 2} more`;
};
