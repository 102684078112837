import React from 'react';
import Drawer from '@mui/material/Drawer';
import SendIcon from '@mui/icons-material/Send';
import { NavLink } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link'; // Import Link for external URLs
import Button from '@mui/material/Button'; // Import the Button component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { red } from '@mui/material/colors';
import { Grid } from '@mui/material';


interface NavDrawerProps {
  open: boolean;
  onClose: (event: React.KeyboardEvent | React.MouseEvent) => void;
  onLogout: () => void;
  isAuthenticated: boolean;  // Add a prop to check if user is authenticated
}

const NavDrawer: React.FC<NavDrawerProps> = ({ open, onClose, onLogout, isAuthenticated }) => {
    const lighterRed = "#EF5350"; // A lighter shade of red

    // Styles for active button
    const activeButtonStyle = {
      textTransform: 'none' as const,
      borderColor: lighterRed,
      color: lighterRed,
      width: '170px',
      padding: '5px 15px',
      border: `1px solid ${lighterRed}`,
      borderRadius: '4px'
    };

    // Styles for non-active button
    const nonActiveButtonStyle = {
      textTransform: 'none' as const,
      backgroundColor: lighterRed,
      color: 'white',
      width: '170px',
      padding: '5px 15px',
      borderRadius: '4px'
    };

  const linkStyle = {
    textDecoration: 'none'
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <div
        role="presentation"
        onClick={onClose}
        onKeyDown={onClose}
        style={{ width: '250px' }}
      >
        <img 
          src="https://i.imgur.com/tPFnxzE.png" 
          alt="Logo" 
          style={{ 
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            height: '75px',
            padding: '20px 0'
          }} 
        />
        <Stack spacing={2} direction="column" alignItems="center">
          <NavLink 
            to="/" 
            style={{ 
              ...linkStyle, 
              ...(window.location.pathname === "/" ? activeButtonStyle : nonActiveButtonStyle),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            Sanger
          </NavLink>
          <NavLink 
            to="/om" 
            style={{ 
              ...linkStyle, 
              ...(window.location.pathname === "/about" ? activeButtonStyle : nonActiveButtonStyle),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            Om
          </NavLink>
          <NavLink 
            to="/send-inn" 
            style={{ 
              ...linkStyle, 
              ...(window.location.pathname === "/send-inn-sang" ? activeButtonStyle : nonActiveButtonStyle),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            Send inn <SendIcon style={{ marginLeft: '8px' }} />
          </NavLink>
          {/* Conditional rendering based on isAuthenticated for Review Sanger */}
          {isAuthenticated && (
            <NavLink 
              to="/innsendinger" 
              style={{ 
                ...linkStyle, 
                ...(window.location.pathname === "/innsendinger" ? activeButtonStyle : nonActiveButtonStyle),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              Review
            </NavLink>
          )}

          {/* Conditional rendering of Logout Button based on isAuthenticated */}
          {isAuthenticated && (
            <Button
              onClick={onLogout}
              style={{
                ...linkStyle,
                ...nonActiveButtonStyle,
                marginTop: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              Logout
            </Button>
          )}
        </Stack>
        
      </div>

            <Grid container spacing={1} justifyContent="center" alignItems="center" marginTop='1rem'>
      <Grid item>
                                              <Link href="https://www.tiktok.com/@russetoppen" target="_blank" rel="noopener noreferrer" sx={{ mr: 10 }}>
                            <FontAwesomeIcon icon={faTiktok} size="2x" style={{ color: red[500] }} />
                        </Link>
      </Grid>
      <Grid item>
                              <Link href="https://www.instagram.com/russetoppen" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} size="2x" style={{ color: red[500] }} />
                        </Link>
      </Grid>
      
      </Grid>
    </Drawer>
  );
};

export default NavDrawer;
