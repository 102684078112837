import * as React from 'react';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation

const AddButton: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/send-inn"); // Redirect to /send-inn
  };

  return (
    <Fab
      color="primary"
      size="medium"
      onClick={handleClick}
      sx={{
        position: 'fixed',
        bottom: '5%', // Adjust this value to position the button correctly
        zIndex: 1050,
        backgroundColor: '#C62828',
        '&:hover': {
            backgroundColor: '#bf5656',
        },
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
        borderRadius: '50%',
        width: '60px',
        height: '60px',
        padding: '12px',
        right: { xs: '2%', md: '7%', custom: '10%', lg: '30%' },
      }}
    >
      <AddIcon style={{ fontSize: '32px' }} />
    </Fab>
  );
};

export default AddButton;
