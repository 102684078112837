import * as React from 'react';
import { 
  Card as MuiCard, 
  CardContent, 
  Typography, 
  Grid, 
  Tooltip, 
  Box
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ArtistCardProps } from './ArtistCardTypes';

// Use styles from SongCard
import { 
  cardMediaContainerStyles,
  cardMediaStyles,
  contentStyles
} from '../SongCard/SongCardStyles';

// Import the formatStreamCount function
import { formatStreamCount } from './ArtistCardHelpers';

const ArtistCard: React.FC<ArtistCardProps> = ({ artist }) => {
  const theme = useTheme();
  return (
    <Box sx={{ marginBottom: '0.8rem', position: 'relative', overflow: 'hidden' }}>
      <MuiCard>
        <Grid container spacing={0} alignItems="center">
          <Grid 
            item 
            xs={6} 
            sm={5} 
            sx={{ [theme.breakpoints.down('sm')]: { paddingLeft: '0rem' } }}
          >
            <Box sx={cardMediaContainerStyles}>
              {artist.image_url ? (
                <Box 
                  component="img"
                  src={artist.image_url}
                  alt={artist.name}
                  sx={cardMediaStyles}
                />
              ) : (
                <div style={{ 
                  position: 'absolute', 
                  top: 0, 
                  left: 0, 
                  width: '100%', 
                  height: '100%', 
                  backgroundColor: '#f0f0f0', 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center' 
                }}>
                  <Typography variant="subtitle1">No Image</Typography>
                </div>
              )}
            </Box>
          </Grid>
          <Grid item xs={6} sm={7}>
            <CardContent sx={{ ...contentStyles, paddingLeft: '1rem' }}>
              <Typography 
                variant="h6" 
                gutterBottom
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: '100%',
                  marginTop: '0rem',
                }}
              >
                {artist.name}
              </Typography>
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ 
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                Total Songs: {artist.song_count}
              </Typography>
              <Typography sx={{ fontSize: '18px' }}>
                {formatStreamCount(artist.stream_count_total)} streams
              </Typography>
              {artist.last_7_days_gain !== null && (
                <Typography sx={{ fontSize: '15px', color: 'green' }}>
                  <Tooltip title="Stream gain in the last 7 days">
                    <span>↑ {artist.last_7_days_gain.toLocaleString()}</span>
                  </Tooltip>
                </Typography>
              )}
            </CardContent>
          </Grid>
        </Grid>
      </MuiCard>
    </Box>
  );
};

export default ArtistCard;
