import * as React from "react";
import {
  Card as MuiCard,
  CardContent,
  Typography,
  Box,
  Grid,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Song } from "./SongCardTypes";
import {
  formatStreamCount,
  formatArtists,
  displayRank,
} from "./SongCardHelpers";
import {
  cardMediaStyles,
  contentStyles,
  cardMediaContainerStyles,
  rankStyles,
} from "./SongCardStyles";

interface CardProps {
  song: Song;
  displayDetails: string[];
  selectedYear: number | null;
  orderByStreamGain?: boolean;
  onClick?: () => void;
}

const Card: React.ForwardRefRenderFunction<HTMLDivElement, CardProps> = (
  { song, displayDetails, selectedYear, onClick, orderByStreamGain },
  ref
) => {
  const theme = useTheme();
  const rankDisplay = displayRank(song, selectedYear, orderByStreamGain);
  
  let customEmoji;
  switch (song.spotifyId) {
    case "1dBfRwPwKEqyKuC6lBQcRz": customEmoji = "👑"; break;
    case "3ffplScWbnuYZc4kLBFCVR": customEmoji = "❤️"; break;
    case "3z9vNfbTaealedKtXW3u6t": customEmoji = "💩"; break;
    default: customEmoji = ""; break;
  }

  // Use a function to safely format weekly stream count or provide a fallback
  const formatWeeklyStreamCount = (count: number | null) => {
    return count !== null ? `${count.toLocaleString()} ↑` : "Ingen data";
  };

  return (
    <Box
      ref={ref}
      sx={{ marginBottom: "0.8rem", position: "relative", overflow: "hidden" }}
      onClick={onClick}
    >
      <MuiCard>
        <Grid container spacing={0} alignItems="center">
          <Grid
            item
            xs={6}
            sm={5}
            sx={{ [theme.breakpoints.down("sm")]: { paddingLeft: "0rem" } }}
          >
            <Box sx={cardMediaContainerStyles}>
              <Box
                component="img"
                src={song.coverArtUrl}
                alt="Cover Art"
                sx={cardMediaStyles}
                loading="lazy"
              />
            </Box>
          </Grid>
          <Grid item xs={6} sm={7}>
            <CardContent sx={{ ...contentStyles, paddingLeft: "1rem" }}>
              <Typography sx={rankStyles(rankDisplay)}>
                {rankDisplay.symbol} {customEmoji}
              </Typography>
              <Typography
                variant="body1"
                component="div"
                gutterBottom
                title={song.title}
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "100%",
                  marginTop: "0rem",
                }}
              >
                {song.title}
              </Typography>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {formatArtists(song.artist)}
              </Typography>
              {displayDetails.includes("streamCount") && (
                <Typography sx={{ fontSize: "18px" }}>
                  {formatStreamCount(song.streamCount)} streams
                </Typography>
              )}
              {song.dataPointsCount === 1 ? (
                <Tooltip title="Data is being gathered">
                  <Typography sx={{ fontSize: "15px", color: "grey" }}>⏳</Typography>
                </Tooltip>
              ) : (
                <Tooltip title="Stream gain in the last 7 days">
                  <Typography sx={{ fontSize: "15px", color: "green" }}>
                    <span>{formatWeeklyStreamCount(song.weeklyStreamCount)}</span>
                  </Typography>
                </Tooltip>
              )}
            </CardContent>
          </Grid>
        </Grid>
      </MuiCard>
    </Box>
  );
};

export default React.forwardRef(Card);
