import axios, { Method } from "axios";
import { useState, useEffect } from "react";


export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(localStorage.getItem("accessToken") !== null);
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    setIsAuthenticated(localStorage.getItem("accessToken") !== null);
  }, []);

  const handleTokenRefresh = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      if (!refreshToken) {
        throw new Error("No refresh token available");
      }

      const response = await axios.post(`${baseURL}/api/token/refresh/`, { refresh: refreshToken });
      if (response.data.access) {
        localStorage.setItem("accessToken", response.data.access);
        setIsAuthenticated(true);
        return response.data.access; // Return new access token
      } else {
        console.error("Failed to refresh token");
        handleLogout();
        return null;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      handleLogout();
      return null;
    }
  };

  const handleLogin = async (username: string, password: string, totpCode: string, recaptchaToken: string) => {
    const payload = {
      username,
      password,
      totp_token: totpCode,
      recaptchaToken
    };

    try {
      const response = await axios.post(`${baseURL}/api/login`, payload);
      if (response.data.access && response.data.refresh) {
        localStorage.setItem("accessToken", response.data.access);
        localStorage.setItem("refreshToken", response.data.refresh);
        setIsAuthenticated(true);
        return true;
      } else {
        console.error("Login failed: No tokens received");
        return false;
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.error("Login error: ", error.response);
      } else {
        console.error("Login error: ", error);
      }
      return false;
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setIsAuthenticated(false);
  };

  const apiRequest = async (url: string, method: Method, data: any = null) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: method,
        url: `${baseURL}${url}`,
        data: data,
        headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {}
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
        const newAccessToken = await handleTokenRefresh();
        if (newAccessToken) {
          return axios({
            method: method,
            url: `${baseURL}${url}`,
            data: data,
            headers: { Authorization: `Bearer ${newAccessToken}` }
          }).then(response => response.data);
        } else {
          handleLogout();
          return null;
        }
      } else {
        throw error;
      }
    }
  };

  return { isAuthenticated, handleLogin, handleLogout, handleTokenRefresh, apiRequest };
};

export {};
