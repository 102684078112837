export const BILLION = 1_000_000_000;
export const MILLION = 1_000_000;
export const TEN_MILLION = 10_000_000;
export const HUNDRED_THOUSAND = 100_000;
export const THOUSAND = 1_000;

export const formatStreamCount = (count: number): string => {
  if (count >= BILLION) return `${Math.floor(count / BILLION)}b`;
  if (count >= TEN_MILLION) return `${Math.floor(count / MILLION)}m`;
  if (count >= MILLION) return `${(count / MILLION).toFixed(1)}m`;
  if (count >= HUNDRED_THOUSAND) return `${Math.floor(count / THOUSAND)}k`;
  if (count >= THOUSAND) return `${(count / THOUSAND).toFixed(1)}k`;

  return count.toString();
};

// As of now, we're not using the displayRank and formatArtists functions in the ArtistCard component.
// You can remove them or keep them for future use.
