import React, { useEffect, useState } from 'react';
import {
  Card, CardContent, CardMedia, CardActions, Pagination,
  Typography, Container, Grid, Button, Box, Paper,
  Accordion, AccordionSummary, AccordionDetails, useTheme, useMediaQuery,
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton
} from '@mui/material';
import { green } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useAuth } from '../../auth/auth'; // Adjust the path according to your file structure


// Interfaces
interface ArtistData {
  spotify_id: string;
  name: string;
}

interface SongSubmission {
  id: number;
  isrc: string;
  spotify_id: string;
  title: string;
  artist_data: ArtistData[];
  year: number;
  album_type: string;
  release_date: string;
  release_date_precision: string;
  cover_art_url: string;
  group: string;
}

interface FormData {
  title: string;
  year: number;
  group: string;
}

const ITEMS_PER_PAGE = 10;

const ReviewSubmissions: React.FC = () => {
  const { apiRequest } = useAuth();

  const [totalCount, setTotalCount] = useState(0);
  const [submissions, setSubmissions] = useState<SongSubmission[]>([]);
  const [filters, setFilters] = useState({ title: '', year: '', albumType: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedSubmission, setSelectedSubmission] = useState<SongSubmission | null>(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const { register, handleSubmit, reset } = useForm<FormData>();

  useEffect(() => {
    fetchSubmissions();
  }, []);

  const getToken = () => {
    return localStorage.getItem("accessToken") || ''; // This will return an empty string if the token is not found
  };

  const handleTokenRefresh = async (): Promise<string | null> => {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
      console.error("No refresh token available.");
      // Handle scenario where there is no refresh token, e.g., redirect to login
      return null;
    }

    try {
      // Adjust the URL to your backend's refresh token endpoint
      const response = await fetch(`${baseURL}/api/token/refresh/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ refresh: refreshToken })
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("accessToken", data.access); // Update the access token in local storage
        return data.access; // Return the new access token
      } else {
        console.error("Failed to refresh token.");
        // Handle failure, e.g., redirect to login
        return null;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      // Handle error, e.g., redirect to login
      return null;
    }
  };


  const fetchSubmissions = async (page: number = 1) => {
    setIsLoading(true);
    
    const queryParameters = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (value) queryParameters.append(key, value);
    });
    queryParameters.append('page', page.toString());

    try {
      const data = await apiRequest(`/api/pending-submissions/?${queryParameters.toString()}`, 'GET');
      if (data) {
        setSubmissions(data.results);
        setTotalCount(data.count);
        setTotalPages(Math.ceil(data.count / ITEMS_PER_PAGE));
      }
    } catch (error) {
      console.error('An error occurred while fetching submissions:', error);
    }

    setIsLoading(false);
  };


  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
    fetchSubmissions(page);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleFilter = () => {
    fetchSubmissions();
  };

  const acceptSubmission = async (id: number) => {
    // Function to make the API request
    const makeRequest = async (token: string) => {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };

      try {
        const response = await fetch(`${baseURL}/api/review/${id}/`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({ status: 'approved', reviewer_comments: 'Approved' })
        });

        if (!response.ok) {
          throw response;
        }
        fetchSubmissions(); // Refresh the submissions list
        return null; // No error
      } catch (error) {
        return error as Response; // Cast to 'Response' type
      }
    };

    // Get the current access token and attempt the request
    const token = getToken();
    let errorResponse = await makeRequest(token);

    // If the request failed due to an expired token, refresh and retry
    if (errorResponse && errorResponse.status === 401) {
      const newToken = await handleTokenRefresh();
      if (newToken) {
        errorResponse = await makeRequest(newToken); // Retry with new token
      }
    }

    // If there's still an error after retrying
    if (errorResponse) {
      console.error('Error accepting submission:', errorResponse);
    }
  };


  const rejectSubmission = async (id: number) => {
    const makeRequest = async (token: string) => {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };

      try {
        const response = await fetch(`${baseURL}/api/review/${id}/`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({ status: 'rejected', reviewer_comments: 'Rejected' })
        });

        if (!response.ok) {
          throw response;
        }
        fetchSubmissions(); // Refresh the submissions list
        return null; // No error
      } catch (error) {
        return error as Response; // Cast to 'Response' type
      }
    };

    const token = getToken();
    let errorResponse = await makeRequest(token);

    if (errorResponse && errorResponse.status === 401) {
      const newToken = await handleTokenRefresh();
      if (newToken) {
        errorResponse = await makeRequest(newToken);
      }
    }

    if (errorResponse) {
      console.error('Error rejecting submission:', errorResponse);
    }
  };


  const openEditDialog = (submission: SongSubmission) => {
    setSelectedSubmission(submission);
    setIsEditDialogOpen(true);
    reset({
      title: submission.title,
      year: submission.year,
      group: submission.group
    });
  };

  const closeEditDialog = () => {
    setIsEditDialogOpen(false);
    setSelectedSubmission(null);
  };

  const onSubmit: SubmitHandler<FormData> = async (data: FormData) => {
    if (selectedSubmission) {
      const makeRequest = async (token: string) => {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        };

        try {
          const response = await fetch(`${baseURL}/api/review/${selectedSubmission.id}/`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(data)
          });

          if (!response.ok) {
            throw response;
          }
          fetchSubmissions(); // Refresh the submissions list
          closeEditDialog(); // Close the dialog
          return null; // No error
        } catch (error) {
          return error as Response; // Cast to 'Response' type
        }
      };

      const token = getToken();
      let errorResponse = await makeRequest(token);

      if (errorResponse && errorResponse.status === 401) {
        const newToken = await handleTokenRefresh();
        if (newToken) {
          errorResponse = await makeRequest(newToken);
        }
      }

      if (errorResponse) {
        console.error('Error updating submission:', errorResponse);
      }
    }
  };


  const handleImageClick = (submission: SongSubmission) => {
    setSelectedSubmission(submission);
  };

  return (
    <Container>
      {/* Dialog for Submission Details */}
      <Dialog open={!!selectedSubmission} onClose={() => setSelectedSubmission(null)} aria-labelledby="submission-details-dialog">
        <DialogTitle id="submission-details-dialog">Submission Details</DialogTitle>
        <DialogContent>
          {selectedSubmission && (
            <>
              <Typography variant="h6" gutterBottom>{selectedSubmission.title}</Typography>
              <Typography variant="body1" gutterBottom>ISRC: {selectedSubmission.isrc}</Typography>
              <Typography variant="body1" gutterBottom>
                <a href={`https://open.spotify.com/track/${selectedSubmission.spotify_id}`} target="_blank" rel="noopener noreferrer">
                  Listen on Spotify
                </a>
            </Typography>
            <Typography variant="body1" gutterBottom>Year: {selectedSubmission.year}</Typography>
            <Typography variant="body1" gutterBottom>Group: {selectedSubmission.group}</Typography>
            <Typography variant="body1" gutterBottom>Album Type: {selectedSubmission.album_type}</Typography>
            <Typography variant="body1" gutterBottom>Release Date: {selectedSubmission.release_date}</Typography>
            <Typography variant="body1" gutterBottom>Release Date Precision: {selectedSubmission.release_date_precision}</Typography>
            <img src={selectedSubmission.cover_art_url} alt="Cover Art" style={{ maxWidth: '100%', marginTop: '10px' }} />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setSelectedSubmission(null)}>Close</Button>
      </DialogActions>
    </Dialog>

    {/* Dialog for Editing Submission */}
    <Dialog open={isEditDialogOpen} onClose={closeEditDialog}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Edit Submission</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Title"
            fullWidth
            variant="outlined"
            {...register('title')}
          />
          <TextField
            margin="dense"
            label="Year"
            fullWidth
            variant="outlined"
            type="number"
            {...register('year')}
          />
          <TextField
            margin="dense"
            label="Group"
            fullWidth
            variant="outlined"
            {...register('group')}
          />
      </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedSubmission(null)}>Close</Button>
        </DialogActions>
        </form>
      </Dialog>

      {/* Dialog for Editing Submission */}
      <Dialog open={isEditDialogOpen} onClose={closeEditDialog}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Edit</DialogTitle>
          <DialogContent>
            <TextField margin="dense" label="Title" fullWidth variant="outlined" {...register('title')} />
            <TextField margin="dense" label="Year" fullWidth variant="outlined" type="number" {...register('year')} />
            <TextField margin="dense" label="Group" fullWidth variant="outlined" {...register('group')} />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeEditDialog}>Avbryt</Button>
            <Button type="submit" color="primary">Lagre</Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Filter Accordion */}
        <Accordion defaultExpanded={!isMobile}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>Filter</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={2}>
              <TextField label="Tittel" name="title" value={filters.title} onChange={handleInputChange} fullWidth={isMobile} />
              <TextField label="År" name="year" value={filters.year} onChange={handleInputChange} fullWidth={isMobile} />
              <Button variant="contained" onClick={handleFilter} sx={{ height: 'fit-content' }}>Filter</Button>
            </Box>
          </AccordionDetails>
        </Accordion>

      {/* Pagination Component */}
      <Box display="flex" justifyContent="center" my={2}>
        <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} color="primary" disabled={isLoading} />
      </Box>

      {/* Submissions Grid or Loading Indicator */}
      {isLoading ? (
        <Typography>Loading...</Typography>
      ) : (
        totalCount > 0 && (
          <Grid container spacing={4}>
            {submissions.map((submission) => (
              <Grid item key={submission.id} xs={12} sm={6} md={4}>
                <Card elevation={3} sx={{ maxWidth: 345, borderRadius: 2, '&:hover': { boxShadow: '0 10px 20px rgba(0,0,0,0.3)' }}}>
                  <CardMedia
                    component="img"
                    height="140"
                    image={submission.cover_art_url}
                    alt={submission.title}
                    sx={{ objectFit: 'cover', cursor: 'pointer' }}
                    onClick={() => handleImageClick(submission)}
                  />
                  <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {submission.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {submission.artist_data.map((artist, index) => (
                  <span key={artist.spotify_id}>
                    {artist.name}{index < submission.artist_data.length - 1 ? ', ' : ''}
                  </span>
                ))}
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'space-between' }}>
              <Button startIcon={<CheckCircleIcon />} variant="contained" sx={{ bgcolor: green[500], color: '#fff' }} onClick={() => acceptSubmission(submission.id)}>
                Accept
              </Button>
              <IconButton onClick={() => openEditDialog(submission)} color="primary">
                <EditIcon />
              </IconButton>
              <Button startIcon={<CancelIcon />} variant="contained" color="error" onClick={() => rejectSubmission(submission.id)}>
                Reject
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
      )
    )}
  </Container>
);
};

export default ReviewSubmissions;