import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { Box, Grid, Paper } from "@mui/material";
import { Song } from "../Cards/SongCard/SongCardTypes";
import useMediaQuery from "@mui/material/useMediaQuery";

interface DialogProps {
  song: Song | null;
  open: boolean;
  onClose: () => void;
  chosenYear?: number | null;
}

const SongDetailDialog: React.FC<DialogProps> = ({
  song,
  open,
  onClose,
  chosenYear,
}) => {
  const matches = useMediaQuery("(max-width:600px)");

  if (!song) return null;

  const formatStats = (count: number | null) => {
    if (count === null || count === undefined) {
      return "No data";
    }
    return count.toLocaleString();
  };

  const getRank = (year: number | null): number | null => {
    if (year === -1) {
      return song.globalRank;
    } else if (year !== null) {
      return song.yearlyRank;
    } else {
      return song.yearlyRank;
    }
  };

  const getTrendRank = (year: number | null): number | null => {
    if (year === -1) {
      return song.globalTrendRank;
    } else if (year !== null) {
      return song.yearlyTrendRank;
    } else {
      return song.yearlyTrendRank;
    }
  };

  const getYearLabel = (year: number | null) => {
    if (year === -1) {
      return "Alle år";
    } else if (year !== null) {
      return year.toString();
    } else {
      return song.year.toString();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      style={{ backgroundColor: "transparent" }}
    >
      <DialogContent style={{ backgroundColor: "#e1e3e2" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "1rem",
          }}
        >
          <img
            src="https://i.imgur.com/tPFnxzE.png"
            alt="Logo"
            style={{ height: "24px" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "1rem",
          }}
        >
          <img
            src={song.coverArtUrl}
            alt="Cover Art"
            style={{
              maxWidth: "150px",
              maxHeight: "150px",
              objectFit: "cover",
              borderRadius: "15px",
            }}
          />
        </div>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Box textAlign="center" mt={-2}>
              <Typography
                variant={matches ? "h6" : "h5"}
                component="div"
                gutterBottom
                fontWeight="bold"
              >
                {song.title}
              </Typography>
              <Typography variant="subtitle1" gutterBottom mt={-1}>
                {song.artist}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5} sm={6}>
            <Paper
              elevation={3}
              sx={{
                padding: 1,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h5" component="span" fontWeight="bold">
                  ▶️
                </Typography>
                <Typography variant="h6" component="span" fontWeight="bold">
                  {formatStats(song.streamCount)}
                </Typography>
                <Typography variant="subtitle2">Totalt</Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={5} sm={6}>
            <Paper
              elevation={3}
              sx={{
                padding: 1,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h5" component="span" fontWeight="bold">
                  📆
                </Typography>
                <Typography variant="h6" component="span" fontWeight="bold">
                  {song.dataPointsCount === 1 ? "⏳" : formatStats(song.weeklyStreamCount)}
                </Typography>
                <Typography variant="subtitle2">Siste uken</Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={5} sm={6}>
            <Paper elevation={3} sx={{ padding: 1, textAlign: "center" }}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h5" component="span" fontWeight="bold">
                  📊
                </Typography>
                <Typography variant="h6" component="span" fontWeight="bold">
                  {getRank(chosenYear ?? null) !== null ? formatStats(getRank(chosenYear ?? null)) : "No data"}#
                </Typography>
                <Typography variant="subtitle2">
                  {getYearLabel(chosenYear ?? null)}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={5} sm={6}>
            <Paper elevation={3} sx={{ padding: 1, textAlign: "center" }}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h5" component="span" fontWeight="bold">
                  🔥
                </Typography>
                <Typography variant="h6" component="span" fontWeight="bold">
                  {getTrendRank(chosenYear ?? null) !== null ? formatStats(getTrendRank(chosenYear ?? null)) : "No data"}#
                </Typography>
                <Typography variant="subtitle2">
                  {getYearLabel(chosenYear ?? null)}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SongDetailDialog;
